/* Apply height and flexbox to html and body to make the footer sticky at the bottom */
html,
body {
    height: 100%;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column;
}

/* Footer styles */
.footer-container {
    margin-top: auto;
    width: 100%;
    background-color: #FCF7F3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-img {
    width: 100%;
    object-fit: contain;
    background-color: #FCF7F3;
}

.footer-messaging {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #FCF7F3;
    padding: 10px;
}

.footer-messaging p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.footer-messaging a img {
    margin-left: 8px;
}

@media (max-width: 768px) {
    .footer-img {
        width: 90%;
    }
}