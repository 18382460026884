.subscription-container {
    display: flex;
    width: 100%;
    background-color: white;
    border-radius: 6px;
    border: 2px solid #ff6633;
    flex-direction: column;
    position: relative;
    /* Add this to enable absolute positioning of the pill */
}

.subscription-header {
    height: 40px;
    width: 100%;
    background-color: #ff6633;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    color: white;
}

.save-pill {
    position: absolute;

    right: 20px;
    background-color: rgba(46, 186, 136, .16);
    color: #2eba88;
    font-weight: 700;
    height: 28px;
    border-radius: 28px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.value-paragraph {
    font-size: 96px;
    font-weight: 700;
    color: #ff6633;
    margin-bottom: 0px;
    margin-top: 20px;
    text-align: center;

}

.subscription-mega-container {
    display: flex;
    flex-direction: column;
    width: 70%;
}

@media (max-width: 768px) {
    .subscription-mega-container {
        width: 90%;
    }

    .value-paragraph {
        font-size: 60px;
    }
}