/* Membership2.css */

.what-youll-get-container {
    width: 100%;
}

@media (min-width: 1400px) {
    .what-youll-get-container {
        width: 800px;
        /* fixed width for desktop, adjust as needed */
    }
}

.perks-container {
    display: flex;
    flex-direction: column;
    /* default for mobile */
}

.perk-item {
    margin-top: 20px;
    width: 100%;
    /* Default to full width for mobile */
}

.perk-image {
    height: 100%;
    object-fit: contain;
    margin-top: 20px;
    width: 100%;
    height: 250px;
}

.besides-container {
    display: flex;
    flex-direction: row;
    /* default for mobile */
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .perks-container {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .perk-item {
        flex: 1 1 calc(50% - 20px);
        /* Two items per row on laptops */
        margin-right: 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .perk-item:nth-child(2n) {
        margin-right: 0;
        /* Remove margin for every 2nd item */
    }
}

@media (min-width: 1280px) {
    .perks-container {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .perk-item {
        flex: 1 1 calc(33.333% - 20px);
        /* Three items per row, minus margins */
        margin-right: 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .perk-item:nth-child(3n) {
        margin-right: 0;
        /* Remove margin for every 3rd item */
    }
}

@media (max-width: 1023px) {
    .perks-container {
        flex-direction: column;
    }

    .perk-item {
        flex: 0 0 100%;
        /* Full width for smaller screens */
        margin-right: 0;
    }
}

/* Ensure no overlapping by using clear: both */
.perk-item::after {
    content: "";
    display: table;
    clear: both;
}

/* Rest of your CSS */
.membership-container2 {
    display: flex;
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-color: #FCF7F3;
    flex-direction: column;
    align-items: center;
}

.membership-container {
    display: flex;
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-color: #FCF7F3;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.row-layout {
    display: flex;
    flex-direction: column;
}

.row-layout-card {
    width: 100%;
    display: flex;
    justify-content: center;
}

.row-layout-info {
    margin-top: 20;
    width: 100%;
}

body {
    font-family: 'DM Sans', sans-serif;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    position: relative;
}

.pola-image {
    width: 100%;
    object-fit: contain;
    background-color: #FCF7F3;
}

.modal {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hidden {
    display: none;
}

.gradient-style {
    width: 100%;
    height: 85%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    background: linear-gradient(to bottom, #FFFFFF, #FF8100);
}

.membership-sizer {
    width: 90%;
}

@media (min-width: 768px) {
    .row-layout {
        flex-direction: row;
    }

    .row-layout-card {
        width: 50%;
        display: flex;
        align-items: center;
    }

    .row-layout-info {
        width: 50%;
    }

    .membership-sizer {
        width: 70%;
    }
}