.events-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 0;
    box-sizing: border-box;
}

.events-title {
    font-size: 35px;
    margin-bottom: 20px;
    color: white;
    margin-top: 0;
    font-family: 'DM Sans', sans-serif;
}

.events-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100%;
}

.event-box {
    background-color: white;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 10px;
    flex: 1 1 calc(25% - 20px);
    box-sizing: border-box;
    height: 400px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
}

.event-image {
    width: 100%;
    object-fit: cover;
    height: 85%;
}

/* Optional: Add some spacing between items */
.event-box+.event-box {
    margin-left: 20px;
}

/* Side scrolling for screens up to medium desktop size */
@media (max-width: 1800px) {
    .events-list {
        overflow-x: auto;
        flex-wrap: nowrap;
    }

    .event-box {
        flex: 0 0 45%;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {
    .events-list {
        overflow-x: auto;
        flex-wrap: nowrap;
    }

    .event-box {
        flex: 0 0 80%;
        margin-right: 10px;
    }

    .events-title {
        font-size: 25px;
    }

    .events-container {
        width: 90%;
    }
}